function Footer() {
  return (
    <footer className="page-footer" id="footer">
      <div className="footer-copyright " id="copyright">
        <div className="container px-8">
          © {new Date().getFullYear()} Copyright Akam Co
        </div>
      </div>
    </footer>
  );
}

export { Footer };
