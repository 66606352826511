import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getMealById } from "../api";
import { Preloader } from "../components/Preloader";

function Recipe() {
  const [recipe, setRecipe] = useState({});
  const { id } = useParams();
  const { goBack } = useHistory();

  useEffect(() => {
    getMealById(id).then((data) => setRecipe(data.meals[0]));
  }, [id]);

  return (
    <>
      {!recipe.idMeal ? (
        <Preloader />
      ) : (
        <div className="recipe text-white m-4">
          <img
            className="recipe rounded-xl  m-2"
            style={{ marginTop: "1em" }}
            src={recipe.strMealThumb}
            alt={recipe.strMeal}
          />
          <h1 className="m-2 mt-8 text-2xl">{recipe.strMeal}</h1>
          <h6 className="m-2">Category: {recipe.strCategory}</h6>
          {recipe.strArea ? (
            <h6 className="m-2">Area: {recipe.strArea}</h6>
          ) : null}
          <p className="m-2">{recipe.strInstructions}</p>
        </div>
      )}
      <button className="btn m-6" onClick={goBack}>
        Go Back
      </button>
    </>
  );
}

export { Recipe };
