function About() {
  return (
    <div>
      <div className="text-white">Section about Akam Co</div>
      <div className="bg-gradient-to-r from-white to-gray-300 text-black p-10 rounded-md shadow-lg max-w-2xl mx-auto my-10">
        <h1 className="text-5xl font-bold mb-4">AKAM COMPANY</h1>
        <h2 className="text-xl font-semibold mb-2">
          Parki Azadi Street Sulaimani
        </h2>
        <p className="text-lg">
          Iraq Mobile: +964 771 6414000 <br />
          Mobile: +964 773 6687000 <br />
          Email:{" "}
          <a href="mailto:info@akamco.co" className="underline">
            info@akamco.co
          </a>
        </p>
      </div>
    </div>
  );
}

export { About };
