const API_KEY = '1';

const API_URL = `https://www.themealdb.com/api/json/v1/${API_KEY}/`;

export { API_URL };






