function Contact() {
  return (
    <div>
      <h1>Contact page</h1>
      AKAM COMPANY Parki Azadi Street Sulaimani, Iraq Mobile: +964 771 6414000
      Mobile: +964 773 6687000 Email: info@akamco.co
    </div>
  );
}

export { Contact };
