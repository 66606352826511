import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <nav className="text-black" id="nav">
      <div class="nav-wrapper">
        <Link to="/" class="brand-logo">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/icons/plate.png"}
          />
        </Link>
        <ul id="nav-mobile" className="text-black right hide-on-med-and-down">
          <li>
            <Link className="text-black " to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="text-black " to="/contacts">
              Contacts
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export { Header };
